

































































































































































































































































































































import { Vue, Component, Ref } from 'vue-property-decorator'
import PersonDetail from '@/components/dashboard/PersonDetail/index.vue'
import pageTitle from '@/components/common/pageTitle.vue'
import pagination from '@/components/common/pagination/index.vue'
import addPersonal from '@/components/common/AddPersonnel/index.vue'
import photoDialog from '@/components/common/photoDialog.vue'
import CopyText from '@/components/common/CopyText.vue'
import TagsDialog from '@/components/common/TagsDialog.vue'
import RadioDialog from '@/components/common/RadioDialog/index.vue'
import FeatureImg from '@/components/dashboard/FeatureImg/index.vue'
import { exportStatistic } from '@/utils/export'

import InputSearch from '@/components/common/InputSearch.vue'
import SecurityText from '@/components/common/SecurityText/index.vue'
import { variables } from '@/libs/theme'
import { tableScrollTop } from '@/utils/dom'
import _ from 'lodash'
import { is } from '@/utils/helpers'

@Component({
  components: {
    PersonDetail,
    pageTitle,
    addPersonal,
    photoDialog,
    RadioDialog,
    pagination,
    CopyText,
    TagsDialog,
    FeatureImg,
    InputSearch,
    SecurityText
  }
})
export default class PersonSearch extends Vue {
  @Ref() readonly paginationNode: pagination
  @Ref() readonly paginationNdePre: pagination
  seachName = null
  loading = false
  PersonList = []
  PersonListPrecise = []
  sels: AnyObj[] = []
  allpersonalTitle = 'allpersonal'
  noNet = false
  noData = require('@/assets/images/dashboard/noData.png')
  showDetailDialog = false
  curPerson: AnyObj | null = null
  cascaderTags = []
  flowDetailTitle = 'flowDetails'
  showTrips = false
  typeRelation = {
    vip: 'VIP',
    black: '黑名单',
    white: '白名单'
  }
  searchPar: AnyObj | null = null
  activeTag = 'indistinct'
  personID = ''
  screenCon: AnyObj | null = null
  activeVistLabel = '期间到访次数'
  exportLoading = false
  personFilterVal: AnyObj = {
    dateRange: [this.$moment().startOf('day'), this.$moment().endOf('day')],
    gender: ''
  }
  tabIndex = '0'
  searchId = ''
  private shadow9Color = variables.shadow9Color

  get rootConfig() {
    return this.$store.state.config?.root
  }

  get root() {
    return this.rootConfig ? this.rootConfig.id : this.$store.state.user.scope
  }

  get isRootAccount() {
    return this.root === this.$store.state.user.scope
  }
  get params() {
    const [startTime, endTime] = this.personFilterVal.dateRange

    return _.omitBy(
      { startTime, endTime, ..._.omit(this.personFilterVal, ['dateRange']) },
      i => !is.ava(i) || (is.num(i) && i <= 0)
    )
  }

  async mounted() {
    this.getfilter()
    this.seachName = null
  }

  showExportDialog() {
    ;(this.$refs.exportDialog as Vue).show()
  }

  async exportResult(exportAll = true) {
    if (this.searchPar !== null) {
      let params = this.searchPar
      this.exportLoading = true
      try {
        if (!exportAll) {
          if (this.sels.length === 0) {
            this.exportLoading = false
            return
          }
          params = { ...this.searchPar, personIds: this.sels.map(i => i.id) }
        }

        let res

        if (this.activeTag === 'precise') {
          if (!this.personID) {
            this.exportLoading = false
            return
          }
          params = { limit: 20, offset: 0, personIds: [this.personID] }

          res = await this.$api.base.personsExport(params)
        } else {
          res = await this.$api.base.personsFindExport(params)
        }

        exportStatistic(res.data, 'csv', 'person_recognize.csv')
        this.exportLoading = false
      } catch (e) {
        this.exportLoading = false
      }
    }
  }

  tripsLeave() {
    this.showTrips = false
  }

  tripsHover() {
    this.showTrips = true
  }

  handleRegister(obj: AnyObj) {
    ;(this.$refs.addPersonalNode as Vue).open('detailAdd', obj)
  }

  async getfilter() {
    this.$nextTick(async () => {
      const { pageSize } = this.paginationNode.getPageInfo()
      await this.PersonSearch(pageSize, 0)
      this.paginationNode.pageNo = 1
    })
  }

  async PersonSearch(pageSize = 20, offset = 0) {
    this.loading = true
    const pn = {
      limit: pageSize,
      offset: offset
    }
    try {
      this.searchPar = this.params
      Object.assign(this.params, pn)
      const resp = await this.$api.base.getPersonSearch(this.params)
      if (resp.data.code === 0) {
        this.PersonList = resp.data.data
        if (resp.data.data && resp.data.data.length != 0) {
          resp.data.data.forEach((r: AnyObj) => {
            if (r.tags && r.tags.length != 0) {
              r.tags.map((e: AnyObj) => {
                r.type = e.type
              })
            }
          })
        }
        this.paginationNode.init({ total: resp.data.count })
        tableScrollTop()
      }
      this.loading = false

      return resp
    } catch (error) {
      if (!error.request) {
        this.loading = false
        this.noNet = true
      } else if (error) {
        this.loading = false
      }
    }
  }

  async refresh(name = '') {
    if (this.activeTag === 'precise') {
      this.searchByID()
    } else {
      this.paginationNode.pageNo = 1
      if (name) this.seachName = null
      const { pageSize } = this.paginationNode.getPageInfo()
      await this.PersonSearch(pageSize, 0)
    }
  }

  pageChange(data: AnyObj) {
    const pageSize = data.pageSize
    const offest = (data.pageNo - 1) * data.pageSize

    this.PersonSearch(pageSize, offest)
  }

  pageChangePrecise() {
    this.searchByID()
  }

  message(msg: string, type: 'success' | 'warning' | 'info' | 'error') {
    this.$message({
      message: msg,
      type: type
    })
  }

  async clickEdit(row: AnyObj) {
    const res = await this.$api.base.getPersonSingle(row.id)
    ;(this.$refs.addPersonalNode as Vue).open('edit', res.data.data)
  }

  intoDeatail(row: AnyObj) {
    this.curPerson = { ...row }
    this.showDetailDialog = true
    const { areaIds } = this.params
    if (this.tabIndex === '0') {
      this.screenCon = {
        date: this.personFilterVal.dateRange,
        areaIds: areaIds || [this.root]
      }
    } else {
      this.screenCon = null
    }
  }

  clickPhotoManage(row: AnyObj) {
    ;(this.$refs.photoDialogNode as Vue).open(row)
  }

  async clickDelete({ id }: AnyObj, index: number, rows: AnyObj[]) {
    this.$confirm('人员一经删除无法恢复，是否确定删除？', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning',
      cancelButtonClass: 'message-cancel-btn'
    })
      .then(async () => {
        const resp = await this.$api.base.getPersonsDelete(id)
        rows.splice(index, 1)
        if (resp.status && resp.status === 204) {
          this.message('删除成功!', 'success')
        }
        this.refresh()
      })
      .catch(() => {
        this.message('已取消删除', 'info')
      })
  }

  getAlreadyPeron(row: AnyObj) {
    this.curPerson = { ...row }
    this.showDetailDialog = true
  }

  clickEditTgs(row: AnyObj) {
    ;(this.$refs.editTagsNode as Vue).open('editTags', row)
  }

  filter(value: string, data: AnyObj) {
    if (!value) return true
    return data.name.indexOf(value) !== -1
  }

  selsChange(val: AnyObj[]) {
    this.sels = val
  }
  preciseSearch(val = '') {
    if (val === '' || val.match(/^[ ]*$/)) return
    this.searchId = val
    this.searchByID()
  }
  async searchByID() {
    this.personID = this.searchId.trim()

    this.$message.closeAll()

    let id = this.personID ? [this.personID] : []

    if (id.length === 0) {
      id = ['abc']
    }
    const params = {
      limit: 20,
      offset: 0,
      personIds: id
    }

    this.loading = true

    try {
      const resp = await this.$api.base.getAllPersonsList(params)
      this.paginationNdePre.init({ total: resp.data.count })
      this.paginationNdePre.pageNo = 1

      this.PersonListPrecise = resp.data.data
    } finally {
      this.loading = false
    }
  }

  tabClick(tab: AnyObj) {
    if (tab.index === '0') {
      this.activeVistLabel = '期间到访次数'
    }
    if (tab.index === '1') {
      this.activeVistLabel = '到访次数'
    }
    this.tabIndex = tab.index
  }
}
